import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import * as Select from '@radix-ui/react-select'
import { useState } from 'react'
import cx from 'classnames'
import { useFormContext } from 'react-hook-form'
import castArray from 'lodash.castarray'
import toNumber from 'lodash.tonumber'
import isNaN from 'lodash.isnan'
import get from 'lodash.get'

interface SelectInputProps {
  options: { label: string; value: string }[]
  name: string
  label?: string
  defaultValue?: string
  value?: string
  required?: boolean
  error?: string | string[]
  placeholder?: string
  className?: string
  classNameDropdown?: string
  onChange?: (value: string) => void
  hint?: string
}

// This component assumes an uncontrolled state, and remix hook form
const SelectInput = ({
  options,
  name,
  label,
  defaultValue,
  required,
  error,
  placeholder,
  className,
  classNameDropdown,
  hint,
  onChange
}: SelectInputProps) => {
  // This is a bit convoluted, but it's so we can still use this component even outside of react-hook-form
  const formContext = useFormContext()
  const register = formContext ? formContext.register : undefined
  const watch = formContext ? formContext.watch : undefined
  const setValue = formContext ? formContext.setValue : undefined
  const { errors: contextErrors = {}, defaultValues = {} } = formContext?.formState || {}

  const contextError = get(contextErrors, name)?.message as string | undefined
  const wrapContextError = contextError ? castArray(contextError) : []
  const errors = (error ? castArray(error) : []).concat(wrapContextError)

  const [cachedValue, setCachedValue] = useState<string | number | undefined>(undefined)
  const value = watch?.(name)
  const registeredAttrs = register?.(name, { onChange }) || {
    name,
    value: cachedValue,
    onChange: event => onChange?.(event.target.value)
  }

  const dv = defaultValue !== undefined ? defaultValue : defaultValues?.[name]

  const saveValueInRHF = (value: string) => {
    // If the value is a number, convert it to a number
    const parsedValue = toNumber(value)
    const finalValue = isNaN(parsedValue) ? value : parsedValue
    setValue?.(name, finalValue)
    setCachedValue(finalValue)
  }

  return (
    <div>
      <label className={cx('block min-w-[70px] space-y-1 text-sm font-bold', className)}>
        <div className="ml-3 flex items-center justify-between">
          {label && (
            <span className={cx(required && "after:ml-0.5 after:text-basePink after:content-['*']")}>{label}</span>
          )}
          {errors.map((error, index) => (
            <span key={index} className="font-sm font-normal text-basePink">
              {error}
            </span>
          ))}
        </div>

        {/* This hidden input will register the value for RHF. This allows us to
          have SelectInput be an uncontrolled component, but still work with
          @radix-ui/react-select, which is designed to be a controlled component */}
        <input type="hidden" {...registeredAttrs} />

        <Select.Root defaultValue={dv?.toString()} onValueChange={saveValueInRHF} value={value?.toString()}>
          <Select.Trigger
            aria-label={label}
            style={{ minHeight: '33.5px' }}
            className={cx(
              'w-full appearance-none  bg-transparent px-3 py-2 leading-tight outline outline-2',
              'relative flex items-center rounded-full',
              'text-dark outline-dark data-[placeholder]:text-light dark:bg-dark dark:text-white dark:outline-gray-300 dark:data-[placeholder]:text-gray-500',
              !required &&
                'invalid:outline-transparent invalid:ring-1 invalid:ring-lightPink invalid:ring-offset-2 invalid:ring-offset-transparent',
              classNameDropdown
            )}>
            <Select.Value placeholder={placeholder ?? 'Select...'} />
            <Select.Icon className="absolute bottom-0 right-3 top-0 flex items-center">
              <ChevronDownIcon className="h-5" />
            </Select.Icon>
          </Select.Trigger>
          <Select.Content className="z-40 rounded-lg bg-white p-2 text-dark shadow-md dark:bg-gray-800 dark:text-white">
            <Select.ScrollUpButton className="flex justify-center">
              <ChevronUpIcon className="h-4" />
            </Select.ScrollUpButton>
            <Select.Viewport>
              {options.map(({ label, value }) => (
                <Select.Item
                  value={value}
                  key={value}
                  className={cx(
                    'relative flex items-center rounded-md p-1 pl-8 text-sm',
                    'focus:bg-baseOrange focus:text-white focus:outline-none'
                  )}>
                  <Select.ItemIndicator className="absolute bottom-0 left-2 top-0 flex items-center">
                    <CheckIcon className="h-5" />
                  </Select.ItemIndicator>
                  {label && <Select.ItemText className="">{label}</Select.ItemText>}
                </Select.Item>
              ))}
            </Select.Viewport>
            <Select.ScrollDownButton className="flex justify-center">
              <ChevronDownIcon className="h-4" />
            </Select.ScrollDownButton>
          </Select.Content>
        </Select.Root>
      </label>
      {hint && <p className="mx-3 mt-2 text-xs font-normal leading-4 text-mid dark:text-light">{hint}</p>}
    </div>
  )
}

export default SelectInput
